import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './Page.styles.scss'

const Page = ({ children }) => {
  return <div className={styles.Page}>{children}</div>
}

Page.propTypes = {
  children: PropTypes.node,
}

export default Page
