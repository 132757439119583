import React from 'react'
import PropTypes from 'prop-types'
import SbEditable from 'storyblok-react'

import { useBloks } from '@/storyblok/hooks/useBloks'
import { BlockRenderer } from '@/storyblok/components/BlockRenderer'
import { blockTypes } from '@/constants/blockTypes'
import Page from '@/templates/Page'

const PageStoryblok = ({ story }) => {
  return (
    <SbEditable content={story.content} key={story.content._uid}>
      <Page>
        <BlockRenderer
          blocks={useBloks(story.content.body || [])}
          types={blockTypes}
        />
      </Page>
    </SbEditable>
  )
}

PageStoryblok.propTypes = {
  story: PropTypes.object,
}

export default PageStoryblok
